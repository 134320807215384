import type { NextPage } from 'next';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { Button, Layout } from 'antd';
import moment from 'moment';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { SideBar } from 'src/components/SideBar';

const Home: NextPage = () => {
  const { t } = useTranslation('common');

  return (
    <Layout
      className={`gx-app-layout full-scroll horizontal-layout full-layout`}
    >
      <Layout>
        <Layout.Content className={`gx-layout-content gx-container-wrap`}>
          <div className="gx-main-content-wrapper">
            <SideBar title={t('Page Not Found')}>
              <p>
                {t(
                  'We could not find the page you where trying to reach, It might be caused by incorrect URL, you can try to go to the home page to. Contact support for more information.'
                )}
              </p>
              <Button
                //@ts-ignore
                type="danger"
                href={`mailto:support@businessradar.com`}
              >
                {t('Contact Technical Support')}
              </Button>
              <Button
                //@ts-ignore
                type="primary"
                href={'/'}
              >
                {t('Go To Homepage')}
              </Button>
            </SideBar>
          </div>
          {/* <BG className="-mb-1 z-10" /> */}
          <Layout.Footer>
            <div className="gx-layout-footer-content">
              ©{moment().year()} Business Radar
            </div>
          </Layout.Footer>
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

// @ts-ignore
export const getStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'login'])),
  },
});

export default Home;
